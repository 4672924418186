
import { Prop, Watch } from 'vue-property-decorator'
import { Options, Vue } from 'vue-class-component';
import { LocalizationClient } from '@/services/Services';
import { StorageServices } from '@/services/StorageServices';
import * as OM from '@/Model';
import store from '@/store';
import { insertAllLanguages } from '@/utils';
import { LocalizationServices } from '@/services/LocalizationServices';

@Options({})
export default class LocalizedText extends Vue {

    @Prop({
        default: ""
    }) localizedKey: string;

    @Prop({
        default: ""
    }) text: string;

    @Prop({
        default: 0
    }) asterisks: number;

    translatedText: string = "";

    @Watch('localizedKey')
    onLocalizedKey(next, prev){
        this.setLocalizedText();
    }

    @Watch('asterisks')
    onnumberAsterisk(){
        this.setLocalizedText();
    }

    mounted(){
        this.setLocalizedText();
        store.watch(state => state.selectedLanguage,
            () => {
                this.setLocalizedText();
        })
    }

    setLocalizedText(){
        this.translatedText = LocalizationServices.GetLocalizedValue(this.localizedKey, this.text);
    }
}
