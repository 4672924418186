import store from '@/store';
import * as OM from '@/Model';
import { StorageServices } from '@/services/StorageServices';
import { LocalizedClient } from './Services';

class _LocalizationServices {
    
    constructor() {
        store.state.selectedLanguage = StorageServices.GetLanguage();
        if (!store.state.selectedLanguage) {
            var keys = Object.keys(store.state.consts.enabledLanguages);
            store.state.selectedLanguage = store.state.consts.enabledLanguages[keys[0]];
        }
    }
    
    SetLanguage(language: string) {
        store.state.selectedLanguage = language;
        StorageServices.SetLanguage(language);
    }
    
    GetLanguage() {
        return store.state.selectedLanguage;
    }
    
    GetLocalizedValue(key: string, value: string): string {
        var existing = store.state.consts.localizedValues.find(x => x.key == key);
        if(existing) {
            var selectedCulture = StorageServices.GetLanguage();
            return existing.values[selectedCulture];
        } else {
            var newValue = new OM.KeyValuePairOfStringAndString();
            newValue.key = key;
            newValue.value = value;
            LocalizedClient.saveLocalizedValue(newValue, false)
            .then(x => {
                var newLocalizedValue = new OM.LocalizedEntity();
                newLocalizedValue.key = key;
                newLocalizedValue.identifier = "";
                store.state.consts.enabledLanguages.forEach(x => {
                    newLocalizedValue.values[x] = value;
                })
                store.state.consts.localizedValues.push(newLocalizedValue);
            })
            return value;
        }
    }

}
export let LocalizationServices = new _LocalizationServices();
