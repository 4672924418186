
import { Prop, Watch } from 'vue-property-decorator'
import { mixins, Options, Vue } from 'vue-class-component';
import { LocalizationClient } from '@/services/Services';
import { StorageServices } from '@/services/StorageServices';
import * as OM from '@/Model';
import store from '@/store';
import { insertAllLanguages } from '@/utils';
import { BaseFormField } from '@/mixins/baseFormField';

@Options({})
export default class LocalizedFormField extends mixins(BaseFormField) {

    languages: string[] = [];
    selectedLanguage: string = "";
    remaining: number = 0;
    empties: { [key: string]: boolean } = {};

    created(){
        insertAllLanguages(this.modelValue);
        this.languages = store.state.consts.enabledLanguages;
        this.selectedLanguage = this.languages[0];
        this.languages.forEach(x => this.empties[x] = false);
        this.checkRemaining();
    }
    
    checkRemaining(){
        this.remaining = 0;
        this.languages.forEach(x => {
            if(!this.modelValue.values[x]){
                this.remaining++;
                this.empties[x] = true;
            } else {
                this.empties[x] = false;
            }
        })
    }

    onUpdate(){
        this.emitModel(this.modelValue);
        this.checkRemaining();
    }

}
