
import { Prop, Watch } from 'vue-property-decorator'
import { mixins, Options, Vue } from 'vue-class-component';
import { LocalizationClient } from '@/services/Services';
import { StorageServices } from '@/services/StorageServices';
import * as OM from '@/Model';
import store from '@/store';
import { insertAllLanguages } from '@/utils';
import { LocalizationServices } from '@/services/LocalizationServices';
import { BaseFormField } from '@/mixins/baseFormField';

@Options({})
export default class FormField extends mixins(BaseFormField) {

}
